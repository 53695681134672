// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Select2
$s2bs5-border-color: $border-color;
@import "~select2/src/scss/core";
@import "~select2-bootstrap-5-theme/src/include-all";

// Custom stuff
@import '../css/app.css';

