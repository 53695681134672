body {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
}

a, a:visited {
    color: #000 !important;
}

a:hover {
    color: #000 !important;
    background-color: #FFF791 !important;
    text-decoration: none !important;
}

h1, h2, h3, .h1, .h2, .h3 {
    font-weight: 900 !important;
}

h4, h5, h6, .h4, .h5, .h6 {
    font-weight: 700 !important;
}

small {
	font-size: 0.7em !important;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-link {
    position: relative;
    display: block;
    color: #343f52;
    background-color: #fff;
    border: 1px solid rgba(164, 174, 198, 0.2);
    transition: all 0.2s ease-in-out;
}

a:hover.page-link {
	background-color: #e9ecef !important;
}

@media (prefers-reduced-motion: reduce) {
    .page-link {
        transition: none;
    }
}
.page-link:hover {
    z-index: 2;
    color: #3f78e0;
    background-color: #fff;
    border-color: rgba(164, 174, 198, 0.2);
}
.page-link:focus {
    z-index: 3;
    color: #3f78e0;
    background-color: #fff;
    outline: 0;
    box-shadow: unset;
}
.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}
.page-item.active .page-link {
    z-index: 3;
    color: #3f78e0;
    background-color: #fff;
    border-color: rgba(164, 174, 198, 0.2);
}
.page-item.disabled .page-link {
    color: #959ca9;
    pointer-events: none;
    background-color: #fff;
    border-color: rgba(164, 174, 198, 0.2);
}
.page-link {
    padding: 0 0;
}
.page-item:first-child .page-link {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
}
.page-item:last-child .page-link {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}
.pagination-lg .page-link {
    padding: 0 0;
    font-size: 1rem;
}
.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
    padding: 0 0;
    font-size: 0.7rem;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.pink {
    color: #E91E63;
}

a:hover.no-link-hover-style {
    text-decoration: none !important;
    background-color: transparent !important;
}

code[class*="language-"],
pre[class*="language-"] {
	font-family: 'Hack', monospace;
	text-align: left;
	white-space: pre-line;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	color: #c3cee3;
	background: #263238;
	font-size: 0.9rem;
	line-height: 1.5em;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

code[class*="language-"]::-moz-selection,
pre[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection,
pre[class*="language-"] ::-moz-selection {
	background: #363636;
}

code[class*="language-"]::selection,
pre[class*="language-"]::selection,
code[class*="language-"] ::selection,
pre[class*="language-"] ::selection {
	background: #363636;
}

:not(pre) > code[class*="language-"] {
	white-space: normal;
	border-radius: 0.2em;
	padding: 0.1em;
}

pre[class*="language-"] {
	overflow: auto;
	position: relative;
	border-radius: 4px;
    margin: 0px;
    padding: 10px;
}

.language-css > code,
.language-sass > code,
.language-scss > code {
	color: #fd9170;
}

[class*="language-"] .namespace {
	opacity: 0.7;
}

.token.atrule {
	color: #c792ea;
}

.token.attr-name {
	color: #ffcb6b;
}

.token.attr-value {
	color: #c3e88d;
}

.token.attribute {
	color: #c3e88d;
}

.token.boolean {
	color: #c792ea;
}

.token.builtin {
	color: #ffcb6b;
}

.token.cdata {
	color: #80cbc4;
}

.token.char {
	color: #80cbc4;
}

.token.class {
	color: #ffcb6b;
}

.token.class-name {
	color: #f2ff00;
}

.token.color {
	color: #f2ff00;
}

.token.comment {
	color: #546e7a;
}

.token.constant {
	color: #c792ea;
}

.token.deleted {
	color: #f07178;
}

.token.doctype {
	color: #546e7a;
}

.token.entity {
	color: #f07178;
}

.token.function {
	color: #c792ea;
}

.token.hexcode {
	color: #f2ff00;
}

.token.id {
	color: #c792ea;
	font-weight: bold;
}

.token.important {
	color: #c792ea;
	font-weight: bold;
}

.token.inserted {
	color: #80cbc4;
}

.token.keyword {
	color: #c792ea;
	font-style: italic;
}

.token.number {
	color: #fd9170;
}

.token.operator {
	color: #89ddff;
}

.token.prolog {
	color: #546e7a;
}

.token.property {
	color: #80cbc4;
}

.token.pseudo-class {
	color: #c3e88d;
}

.token.pseudo-element {
	color: #c3e88d;
}

.token.punctuation {
	color: #89ddff;
}

.token.regex {
	color: #f2ff00;
}

.token.selector {
	color: #f07178;
}

.token.string {
	color: #c3e88d;
}

.token.symbol {
	color: #c792ea;
}

.token.tag {
	color: #f07178;
}

.token.unit {
	color: #f07178;
}

.token.url {
	color: #fd9170;
}

.token.variable {
	color: #f07178;
}

.fs-7 {
	font-size: 0.875rem;
}

.fs-8 {
	font-size: 0.75rem;
}

.form-check-label {
	font-size: 0.875rem;
}

textarea#content.form-control {
	font-family: Hack, monospace;
	font-size: 0.875rem;
}

